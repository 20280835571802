@import 'App.scss';

.tutorial-map-controls {
  &__container {
    align-items: $ctr;
    display: $d-flx;
    flex-direction: column;
    justify-content: $ctr;
    position: $pos-rel;
    top: 40px;

    &__title {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      line-height: 20.7px;
      color: $color-dark-blue;
      margin-bottom: 15px;
    }

    p {
      align-self: $ctr;
      font-size: $font-size-md;
      font-weight: $font-weight-light;
      line-height: $rem-round;
      margin: -3px;
      max-width: 255px;
    }
  }

  &__step-container {
    column-gap: 0.625rem;
    display: $d-flx;
    margin-right: auto;
    min-height: 60px;
    flex-direction: column;
    row-gap: 15px;
    width: 80%;
    margin: auto;

    svg {
      align-self: $ctr;
      min-width: 24px;
      max-width: 24px;
    }
    .step {
      display: flex;
      align-items: center;
      column-gap: 15px;
      margin-bottom: 15px;
      .bravo {
        text-align: center;
        margin: auto;
        margin-top: 30px;
        .zone-card {
          margin-top: 50px;
        }
      }
      .finished {
        text-align: center;
        margin: auto;
        margin-top: 30px;
        .bonasavoir {
          margin-top: 50px;
        }
      }
    }
  }

  &__step-label-bold {
    font-weight: $font-weight-bold;
  }

  &__buttons {
    column-gap: 75px;
    display: $d-flx;
    flex-direction: row;
    position: $pos-rel;
    top: multiply($m-base, 2);
    justify-content: flex-end;
    width: 75%;
  }

  &__no-card-border {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-factual {
    border-radius: $border-radius-base;
    border: none;
    height: 0px;
    width: 0px;
    z-index: $z-foreground;
  }

  &__no-card-border-solution {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-blue dashed;
    border-radius: $border-radius-base;
  }

  &__progress-bar {
    margin-top: 8px;
    margin-bottom: -6px;
    width: 135px;
    height: 4px;
    background-color: $bg-color-gray;
    border-radius: 8px;
  }

  &__progress-bar-fill {
    height: 100%;
    background-color: $font-color-pink;
    border-radius: 8px;
  }
}

.card-animated {
  @keyframes drag-n-drop {
    0% {
      visibility: visible;
    }

    25% {
      transform: translate(-150px, 50px);
    }

    75% {
      transform: translate(var(--tutorial-animation-translate-x), var(--tutorial-animation-translate-y));
    }

    100% {
      transform: translate(var(--tutorial-animation-translate-x), var(--tutorial-animation-translate-y));
      visibility: hidden;
    }
  }

  animation: drag-n-drop 2s cubic-bezier(0.33, 0.35, 0.5, 0.96) 1;
}
