.sectors {
  &__container {
    display: flex;
    align-items: center;
    gap: 30px;
    > div {
      align-self: flex-start;
    }
    h1 {
      font-family: Helvetica, sans-serif;
      font-size: 22px;
      font-weight: 700;
      color: #2d1664;
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }
  &__or {
    margin-top: 17px;
  }
  &__new {
    .text-input-container {
      display: flex;
      .sector-new_form__input-label {
        display: none;
      }
    }
  }
}
.btn-sectors {
  margin-top: auto;
}
