%abstract-lot {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 16rem;
  border-radius: $rem-round;
  background-color: $font-color-off-white;
  box-shadow: -3px 3px 6px rgba(39, 43, 47, 0.12);
}

%abstract-lot-title {
  color: $color-dark-blue;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  margin-left: $rem-round;
  text-align: left;
}
