@import 'App.scss';

.edit-card-zones-controls {
  &__title {
    align-items: $ctr;
    display: $d-flx;
    flex-direction: column;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: 20.7px;
    margin: $null;
    position: $pos-rel;
    top: 75px;
  }

  &__container {
    align-items: $ctr;
    display: $d-flx;
    flex-direction: column;
    justify-content: $ctr;
    position: $pos-rel;
    top: 85px;

    p {
      font-size: 14px;
      text-align: $ctr;
      white-space: pre-wrap;
    }
  }

  &__buttons {
    column-gap: 100px;
    display: $d-flx;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    position: $pos-rel;
    top: multiply($m-base, 2);
  }

  &__button {
    display: $d-flx;
    justify-content: $ctr;
    margin-top: multiply($m-base, 2);
  }

  &__no-card-border {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-factual {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-light-pink dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-thematique {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-solution {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-blue dashed;
    border-radius: $border-radius-base;
  }
}
