@import 'App.scss';

.card-form {
  &__container {
    margin: auto auto auto auto;
  }

  &__module {
    cursor: grab;
    display: $d-flx;
    user-select: none;
  }

  &__content {
    border: none;
  }

  &__image {
    height: 215px;
  }

  &__tutorial {
    cursor: default;
  }

  &__close-button {
    align-self: $ctr;
    border-radius: 11px;
    border: $null;
    cursor: $c-pointer;
    display: $d-flx;
    height: 24px;
    width: 24px;
    margin: $null;
    margin-left: auto;
    margin-right: auto;

    svg {
      height: $height-full;
      scale: 1;
      width: $width-full;
    }
  }
}

.hover-map {
  background-color: rgba(45, 22, 100, 0.5);
  border-radius: 5px;
}

.text-image {
  &__title {
    font-weight: $font-weight-bold;
    height: 36px;
    color: $color-dark-blue;
  }
}

.image {
  img {
    -webkit-user-drag: none;
    width: $width-full;
    height: $height-full;
  }
}

.imageTxt {
  width: auto;
  height: 210px;
  img {
    -webkit-user-drag: none;
    width: $width-full;
    height: $height-full;
    object-fit: contain;
  }
}

.blink-animation {
  animation: border-blinking 1.5s ease-in-out infinite;
  transition: border-width 0.5s ease;
}

@keyframes border-blinking {
  0%,
  100% {
    border-width: 4px;
  }
  50% {
    border-width: 8px;
  }
}

.zoom-visuel {
  column-gap: 29px;
  display: $d-flx;
  margin-top: 10px;
  margin-left: 5px;
  width: 80%;

  div {
    column-gap: 8px;
    color: $color-dark-blue;
    display: $d-flx;

    &:hover {
      color: $font-color-pink;
    }

    &:focus {
      color: $font-color-pink;
    }
  }
  span {
    color: inherit;
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
    text-decoration: $deco-under;

    &:hover {
      cursor: $c-pointer;
    }
  }

  svg {
    color: inherit;
    vertical-align: middle;
  }
}
