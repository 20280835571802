@import 'App.scss';

.new-map-popup {
  padding: 1rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  p {
    text-align: center;
    font-size: 18px;
  }

  &__close-icon-container {
    cursor: $c-pointer;
    position: $pos-abs;
    padding: 12px;
    right: 3px;
    top: 0;
  }

  &__card-wrapper {
    border-radius: 7px;
    height: fit-content;
    position: $pos-rel;
    width: fit-content;
  }

  &__text-info-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    input[type='file'] {
      display: none;
    }

    .map-bg-upload-preview {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: $color-dark-blue;
      font-weight: 500;
      margin-top: 0.5rem;
      background-color: #fff;
      border: 1px solid $color-dark-blue;
      border-radius: 5px;
      cursor: pointer;
      flex-grow: 1;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &:not([style*='url("")']) {
        // background-image is not empty
        color: transparent;
        background-color: transparent;
      }
    }

    .file-name {
      color: $color-dark-blue;
      font-weight: 700;
      font-size: 0.7rem;
    }

    .buttons {
      margin-left: auto;
      margin-top: 15px;
      gap: 10px;
      display: flex;
    }
  }
}
