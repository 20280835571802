@import 'App.scss';

.map-selector {
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: $font-color-off-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  ul {
    margin: 0;
    gap: 1.5rem;
    display: flex;
    padding: 1.5rem;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;

    .map-item {
      margin: 0;
      cursor: pointer;
      width: 8rem;
      height: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 6px;
      border: 2px solid $border-color;
      position: relative;

      &__preview {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
        background-color: white;
      }

      &__delete {
        background-color: $bg-color-light-gray;
        border: 1px solid $border-color;
        border-radius: 100vmax;
        position: absolute;
        padding: 4px;
        width: 14px;
        height: 14px;
        right: -12px;
        top: -12px;

        &:hover,
        &:focus-visible {
          filter: invert(1);
          border-color: transparent;
          background-color: invert($required-field-color);
        }
      }

      &.selected {
        box-shadow: 0 0 0px 2px $border-color;
      }
      &.new-map {
        justify-content: center;
        gap: 0.5rem;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}
