@import 'App.scss';

.card-popup {
  align-items: $ctr;
  display: $d-flx;
  justify-content: $ctr;

  //------------
  // Main styles
  //------------

  &__container {
    @extend %abstract-card-container;
    gap: 23px;
  }

  &__module {
    @extend %abstract-card-module;
    border: $font-color-beige 4px solid;
    justify-content: unset;
    color: #a09bc7;
  }

  &__thematique {
    border: $font-color-beige 4px solid;
  }

  &__factuel {
    border: $font-color-light-pink 4px solid;
  }

  &__solution {
    border: $font-color-light-blue 4px solid;
  }

  &__content {
    @extend %abstract-card-content;

    &.thematique {
      border: $font-color-beige 1px dashed;
    }

    &.factuel {
      border: $font-color-light-pink 1px dashed;
    }

    &.solution {
      border: $font-color-light-blue 1px dashed;
    }

    color: $bg-color-dark-gray;
    flex-direction: column;

    #upload-span__textarea-image {
      margin-bottom: 20px;
      grid-gap: 12.5px;
    }

    #upload-span__image {
      margin-bottom: 55px;
    }
  }

  &__buttons {
    @extend %abstract-base-flexbox-buttons;
  }

  //----------------
  // Specific styles
  //----------------

  &__textimage {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }

  &__input {
    border: $font-color-beige-dark dashed;
    border-width: 1px 1px 0 1px;
    &.thematique {
      border: $font-color-beige 1px dashed;
      border-width: 1px 1px 0 1px;
    }

    &.factuel {
      border: $font-color-light-pink 1px dashed;
      border-width: 1px 1px 0 1px;
    }
    &.solution {
      border: $font-color-light-blue dashed;
      border-width: 1px 1px 0 1px;
    }

    input {
      width: 100%;
      border: none;
      padding: 0;
      background: $bg-color-white;
      height: 30px;

      &::-webkit-input-placeholder {
        color: $bg-color-dark-gray;
        font-size: $font-size-md;
        font-weight: $font-weight-light;
      }
    }
  }

  &__imagecontainer {
    position: relative;
    height: 100%;
    border: $font-color-beige-dark 1px dashed;
    &.thematique {
      border: $font-color-beige 1px dashed;
    }

    &.factuel {
      border: $font-color-light-pink 1px dashed;
    }

    &.solution {
      border: $font-color-light-blue 1px dashed;
    }
  }

  &__imageplaceholder {
    position: absolute;
    width: 220px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;

    label {
      font-size: 12px;
    }
  }

  &__textarea {
    border: none;
    height: 160px;
    overflow-y: hidden;

    &::-webkit-input-placeholder {
      color: $bg-color-dark-gray;
      font-size: $font-size-md;
      font-weight: $font-weight-light;
    }
  }

  &__textarea-image {
    border: none;
    border-bottom: $font-color-beige-dark 1px dashed;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
    height: 24px;
    max-height: 24px;
    padding: $null;
    text-align: $ctr;
    width: $width-full;

    &::-webkit-input-placeholder {
      color: $bg-color-dark-gray;
    }

    &__image {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      &-uploaded {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  //--------------
  // Shared styles
  //--------------

  &__image,
  &__textarea-image__image {
    align-items: $ctr;
    cursor: pointer;
    display: $d-flx;
    flex-wrap: wrap;
    font-size: $font-size-sm;
    height: $height-full;
    white-space: pre-wrap;

    &-uploaded {
      cursor: default;
      height: $height-full;
      width: 100%;
      z-index: $z-foreground-max;
    }
  }

  &__textarea,
  &__textarea-image {
    background-color: $bg-color-white;
    color: $color-dark-blue;
    resize: none;
    outline: none;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  &__textarea,
  &__textarea-image,
  &__image {
    font-family: $f-hel;
  }
}

//-----------
// Tag styles
//-----------

.text,
.text-image {
  textarea::placeholder {
    text-decoration: none;
    font-weight: $font-weight-light;
  }
}

.image,
.text-image {
  input[type='file'] {
    display: none;
  }
}

.image,
.text-image {
  span {
    color: inherit;
    display: $d-flx;
    font-size: $font-size-md;
    font-weight: $font-weight-light;
    gap: 12.5px;
    position: $pos-abs;
    z-index: 1;
  }
}

#upload-span__textarea-image {
  gap: 12.5px;
  display: flex;
  justify-content: center;
}
