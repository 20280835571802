@import 'App.scss';

.container-lot {
  @extend %abstract-lot;

  &__image {
    width: 100%;
    min-height: 6rem;
    max-height: 6rem;
    object-fit: cover;
    line-height: 6rem; // align image alt text
    background-color: rgba($color-light-gray, 0.5);
  }

  &__title {
    @extend %abstract-lot-title;
  }

  &__time,
  &__trophy {
    align-items: $ctr;
    display: $d-inflx;
    gap: $m-base;
    height: $rem-round;
    margin-left: $rem-round;
    margin-bottom: 10px;
    text-align: $lft;
    width: $width-full;

    p {
      font-weight: $font-weight-bold;
      margin: $null;
    }
  }

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;

    > img + * {
      flex-grow: 1;
    }

    &.theme__blue,
    &.theme__blue span {
      color: $font-color-light-blue;
    }

    &.theme__pink,
    &.theme__green span {
      color: $font-color-light-pink;
    }

    &.theme__green,
    &.theme__green span {
      color: #9980d4;
    }

    &.theme__yellow,
    &.theme__yellow span {
      color: $font-color-beige;
    }
  }

  &__button {
    display: $d-flx;
    width: $width-full;
    justify-content: flex-end;

    button {
      margin: 6px $rem-round $rem-round 0;
    }
  }

  &__ordre-management {
    display: $d-flx;
    margin-left: $rem-round;

    img {
      margin-left: 5px;
    }
  }

  &__manage-buttons {
    display: $d-flx;
    justify-content: space-between;
    width: $width-full;

    button {
      margin-right: $rem-round;
      margin-top: 6px;
    }
  }
}

.off {
  pointer-events: none;
  opacity: 0.5;
}

.locked {
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  background-color: $font-color-off-white;

  img {
    height: 100%;
    padding: 1rem 0;
    object-fit: contain;
    max-height: 16rem - 2rem; // max-height in abstract-lot - padding
  }
}

.blank {
  opacity: 0;
  display: none !important;
}
