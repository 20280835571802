@import 'App.scss';

.map {
  display: grid;

  &:not(.preview) {
    grid-template-columns: auto 354px;
  }

  &-progression {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eef1f7;
  }

  &__container-left {
    background-color: $bg-color-map-gray;
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    display: table-column;
    width: auto;

    &-admin {
      min-width: 685px;
    }
  }

  &__lbm-logo {
    padding: 20px;
  }

  &__container-right {
    border-bottom-right-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: table-column;
    height: 85vh;
    width: 354px;
  }

  &__map-container {
    border-bottom-left-radius: $border-radius-base;
    border-top-left-radius: $border-radius-base;
    height: $height-full;
    left: $null;
    position: $pos-rel;
    top: $null;
  }

  &__background-image {
    height: auto;
    max-height: 85vh;
    max-width: 100%;
    width: auto;
  }

  &__test {
    z-index: 100;
    position: absolute;
    top: $null;
    left: 50px;
    font-size: 100px;
  }

  &__tools {
    bottom: $null;
    bottom: $rem-round;
    display: $d-flx;
    flex-direction: column;
    gap: $gap-base;
    height: fit-content;
    position: $pos-abs;
    right: $rem-round;
    width: multiply($rem-round, 2);
    z-index: $z-foreground;

    svg {
      display: $d-flx;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__buttons {
    background-color: $color-dark-blue;
    border-radius: $rem-round;
    border: $null;
    color: $font-color-off-white;
    font-weight: $font-weight-bold;
    height: multiply($rem-round, 2);
    margin: $null;
    width: multiply($rem-round, 2);

    &:hover {
      background-color: $bg-color-dark-gray;
      color: $color-dark-blue;
    }
  }
}

.map-container {
  max-width: 100%;
  max-height: 100%;
}

.offset {
  &__top {
    background: transparent;
    top: -50px;
    left: 0;
    right: 80px;
    margin-left: auto;
    width: 85%;
    height: 105px;
    position: absolute;
    z-index: 999;
  }
  &__right {
    background: transparent;
    top: 0;
    right: -35px;
    bottom: 0;
    width: 110px;
    height: 99%;
    position: absolute;
    z-index: 999;
  }

  &__bottom {
    background: transparent;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: auto;
    width: 88%;
    height: 155px;
    position: absolute;
    z-index: 999;
  }

  &__left {
    background: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    margin-top: auto;
    width: 75px;
    height: 93%;
    position: absolute;
    z-index: 999;
  }
}

.badge-drag {
  width: 40px;
  height: 40px;
  background: rgba($bg-color-gray, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  margin: auto;
  z-index: 99;
  &.right,
  &.left {
    top: 0px;
    bottom: 0px;
  }
  &.top,
  &.bottom {
    left: 0px;
    right: 0px;
  }

  &.bottom {
    bottom: 15px;
  }
  &.right {
    right: 15px;
  }
  &.top {
    top: 15px;
  }
  &.left {
    left: 15px;
  }

  img {
    transform: scale(1.5);
  }
}
