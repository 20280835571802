@import 'App.scss';

.edit-lot-map {
  &__name {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  &__buttons {
    display: flex;
    margin-top: 30px;
    @extend %abstract-base-flexbox-buttons;
  }
}
