@import 'App.scss';

.stats {
  &__number {
    margin-bottom: 30px;
    &:not(:first-child) {
      margin-top: 30px;
    }

    font-size: $font-size-lg;
  }

  &__pink-number {
    color: $font-color-pink;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    font-family: $f-hel;
    font-style: normal;
    font-weight: $font-weight-light;
    font-size: $font-size-md;
    color: $color-dark-blue;
    overflow: scroll;

    table {
      border-collapse: collapse;
      width: 80%;
      margin-bottom: 30px;
    }

    th,
    td {
      border: 1px solid $color-dark-blue;
      padding: 15px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      background-color: $color-dark-blue;
      color: white;
    }
  }

  &__loader {
    border: 3px solid $color-light-gray; /* Light grey */
    border-top: 3px solid $color-dark-blue; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    margin-left: 30px;
    animation: spin 2s linear infinite;
  }
}
