@import 'App.scss';

.game-map-controls {
  &__container {
    align-items: $ctr;
    display: $d-flx;
    flex-direction: column;
    justify-content: $ctr;
    position: $pos-rel;
    top: 5%;

    .title {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }

    p {
      font-size: $font-size-md;
    }
  }

  &__card-not-set {
    p {
      font-size: $font-size-sm;
    }

    span {
      color: $font-color-pink;
      font-weight: $font-weight-bold;
    }
  }

  &__card-container {
    height: 282px;
    width: 282px;
    &.disable-link a {
      text-decoration: none;
      color: $color-dark-blue;
      font-style: italic;
      cursor: grab;
    }
  }

  &__informations {
    column-gap: 29px;
    display: $d-flx;
    margin-top: 11px;
    margin-bottom: 25px;
    width: 80%;

    &.full-text {
      column-gap: 15px;
      justify-content: center;
    }

    div {
      column-gap: 8px;
      color: $color-dark-blue;
      display: $d-flx;

      &:hover {
        color: $font-color-pink;
      }

      &:focus {
        color: $font-color-pink;
      }
    }

    a,
    span {
      color: inherit;
      font-weight: $font-weight-light;
      font-size: $font-size-sm;
      text-decoration: $deco-under;

      &:hover {
        cursor: $c-pointer;
      }
    }

    svg {
      color: inherit;
      vertical-align: middle;
    }
  }

  &__buttons {
    display: $d-flx;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    position: $pos-rel;
    top: multiply($m-base, 2);
    justify-content: space-evenly;
  }

  &__button {
    display: $d-flx;
    justify-content: $ctr;
    margin-top: multiply($m-base, 2);
  }

  &__no-card-border {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-factual {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-light-pink dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-thematique {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-beige dashed;
    border-radius: $border-radius-base;
  }

  &__no-card-border-solution {
    width: $width-card;
    height: $height-card;
    border: 4px $font-color-blue dashed;
    border-radius: $border-radius-base;
  }

  &__progress-bar {
    margin-top: 8px;
    margin-bottom: -6px;
    width: 135px;
    height: 4px;
    background-color: $bg-color-gray;
    border-radius: $border-radius-base;
  }

  &__progress-bar-fill {
    height: $height-full;
    background-color: $font-color-pink;
    border-radius: $border-radius-base;
  }
}

.flex-display {
  display: $d-flx;
}
