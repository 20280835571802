// CONTAIN ONLY IMPORTS
// -- PRIMARY SASS FILE --
// if you want more informations about sass
// architecture structure see : https://gist.github.com/AdamMarsden/7b85e8d5bdb5bef969a0
@import 'sass/sass-utils/mixins';
@import 'sass/sass-utils/variables';
@import 'sass/sass-utils/functions';

@import 'sass/base/buttons';
@import 'sass/components/cards';
@import 'sass/components/popups';
@import 'sass/components/notifications';
@import 'sass/components/lots';
@import 'sass/components/buttons.scss';
@import 'sass/base/_buttons.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-dav {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}

.isDragged {
  z-index: 101;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.text-right {
  text-align: right;
}

.loader-spinner {
  border: 3px solid $color-light-gray; /* Light grey */
  border-top: 3px solid $color-dark-blue; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  margin-left: 30px;
  animation: spin 2s linear infinite;
}
