@import 'App.scss';
.accordion {
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  //transition: 0.4s;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: transparent;
  display: none;
  overflow: hidden;
  &.active {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    min-height: 110px;
  }
}
.title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-radius: 3px;
    border-top: 10px solid $font-color-pink;
  }

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-radius: 3px;
    border-left: 10px solid $color-dark-blue;
    margin-right: 5px;
  }
  .trash-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 16px;
      margin: auto;
    }
    &:hover {
      cursor: pointer;
      border-radius: 50%;
      background-color: $bg-color-light-gray;
    }
  }
}
