@import 'App.scss';

// Ids
#popup-file,
#popup-file-text-image {
  background-color: $trsp;
  color: $trsp;
  cursor: $c-pointer;
  display: $d-blk;
  z-index: $z-foreground-max;
  height: 250px;
  position: absolute;
}

#popup-file-text-image {
  margin-top: 24px;
  width: 100%;
  height: 100%;
  position: relative;
}

// Main classes
.container-popup {
  background-color: rgba(45, 22, 100, 0.5);
  backdrop-filter: blur(15px);
  height: 100vh;
  position: $pos-fix;
  top: $null;
  width: $width-full;
  z-index: $z-foreground-max;

  .badgelocked,
  .continue,
  .deconnexion,
  .deconnexion-token,
  .delete-card,
  .duplicate-popup-confirm,
  .finish-lot,
  .home,
  .locked-popup-confirm,
  .publication,
  .remove-card,
  .screen-size,
  .unlock-next-step-popup-confirm,
  .unsaved-map,
  .validate,
  .validation {
    background-color: $bg-color-pure-white;
    color: $color-dark-blue;
    display: $d-flx;
    height: 9.813rem;
    text-align: $ctr;
    width: 16.875rem;
    @extend %abstract-popup;
  }

  .badge {
    background-color: white;
    color: $color-dark-blue;
    display: $d-flx;
    flex-direction: column;
    align-items: center;
    height: 157px;
    text-align: $ctr;
    width: 270px;
    @extend %abstract-popup;

    &__img {
      object-fit: contain;
      width: 94px;
      height: 94px;
      padding-top: 10px;
    }
    &__p {
      position: absolute;
      font-weight: bold;
      width: 238px;
      left: 7%;
      bottom: 0;
    }

    &__close {
      cursor: $c-pointer;
      position: $pos-abs;
      left: 90%;
      top: 5%;
    }
  }

  .duplicate-popup-confirm {
    height: fit-content;
    width: 270px;

    p:first-child {
      color: $color-validation;
    }
  }
  .duplicate-popup-confirm {
    height: fit-content;
    width: 270px;

    p:first-child {
      color: $color-validation;
    }
  }

  .publication {
    height: 10.813rem;
  }

  .badgelocked,
  .continue,
  .deconnexion,
  .delete-card,
  .duplicate-popup-confirm,
  .finish-lot,
  .home,
  .locked-popup-confirm,
  .publication,
  .remove-card,
  .unlock-next-step-popup-confirm,
  .unsaved-map,
  .validate {
    flex-direction: column;

    &__warning {
      align-self: flex-start;
      margin-bottom: 21px;

      @extend %abstract-warning;
    }

    &__warning-center {
      align-self: $ctr;
      font-weight: $font-weight-bold;

      @extend %abstract-warning;
    }

    &__link {
      border-bottom: 0.5px;
      border-left: $null;
      border-top: 0.5px;
      border-right: $null;
      border-bottom-color: $color-light-gray;
      border-top-color: $color-light-gray;
      border-style: solid;
      color: $color-dark-blue;
      cursor: $c-pointer;
      display: $d-blk;
      font-family: $f-hel;
      font-size: $font-size-md;
      font-weight: $font-weight-light;
      padding-bottom: 14px;
      padding-top: 13.5px;
      text-decoration: none;
    }

    &__link:last-child {
      border-top: $null;
      border-bottom: $null;

      &.publish {
        border-top: 0.5px solid $color-light-gray;
      }
    }

    &__link:hover {
      font-weight: $font-weight-bold;
    }
  }

  .continue,
  .delete-card,
  .finish-lot,
  .publication,
  .remove-card,
  .unsaved-map,
  .validate,
  .badgelocked,
  .home {
    height: fit-content;
  }

  .deconnexion-token {
    &__warning {
      align-self: $ctr;
      @extend %abstract-warning;
    }

    &__close {
      cursor: $c-pointer;
      margin: 30px;
      width: 0.875rem;
    }
  }

  .remove-card {
    &__warning {
      margin-bottom: $null;

      p {
        font-weight: $font-weight-bold;
      }
    }
  }

  .screen-size {
    &__warning {
      align-self: $ctr;
      @extend %abstract-warning;
    }
  }

  .locked-popup-confirm {
    &__close {
      cursor: $c-pointer;
      display: $d-blk;
      margin-left: auto;
      margin-right: $m-base;
      margin-top: $m-base;
    }
  }

  .unlock-next-step-popup-confirm {
    p:first-child {
      color: $color-validation;
    }

    &__close {
      cursor: $c-pointer;
      display: $d-blk;
      margin-left: auto;
      margin-right: $m-base;
      margin-top: $m-base;
    }
  }

  .add-card,
  .edit-card {
    background-color: $bg-color-pure-white;
    color: $color-dark-blue;
    display: $d-flx;
    text-align: $ctr;
    padding: 20px;
    width: 300px;
    justify-content: $ctr;
    @extend %abstract-popup;
  }

  .display-zone-cards {
    @extend %abstract-popup;
    background-color: $bg-color-pure-white;
    display: $d-flx;
    height: fit-content;
    justify-content: $ctr;
    max-height: 500px;
    min-height: 350px;
    width: 650px;
  }

  .duplicate-popup {
    @extend %abstract-popup;
    background-color: $bg-color-pure-white;
    display: $d-flx;
    justify-content: $ctr;
    flex-direction: row;
    height: 260px;
    width: 400px;
  }

  .display-zone-edit,
  .display-new-map {
    @extend %abstract-popup;
    background-color: $bg-color-pure-white;
    display: $d-flx;
    justify-content: $ctr;
    height: 320px;
    width: 480px;
  }

  .user-information-popup,
  .sector-selection-popup {
    @extend %abstract-popup;
    background-color: $bg-color-pure-white;
    display: $d-flx;
    justify-content: $ctr;
    flex-direction: row;
    max-height: 312px;
    width: 408px;
  }

  .user-information-popup {
    max-height: 350px;
  }

  .zoom-image {
    @extend %abstract-popup;
    background-color: $bg-color-pure-white;
    display: $d-flx;
    flex-direction: column;
    justify-content: $ctr;
    height: 480px;
    width: 530px;

    &__close {
      cursor: $c-pointer;
      position: $pos-abs;
      right: 16px;
      top: 16px;
    }

    &__container {
      align-self: $ctr;
      height: 380px;
      max-height: 380px;
      max-width: 420px;
      width: 420px;
      padding-top: 15px;

      img {
        width: $width-full;
        height: $height-full;
        object-fit: contain;
        overflow: hidden;
      }
    }

    &__title {
      color: $color-dark-blue;
      font-size: 16px;
      font-weight: $font-weight-light;
      text-align: $ctr;
      position: $pos-abs;
      transform: translate(-50%);
      left: 50%;
      top: 12px;
    }

    .container-btn-rollback {
      text-align: end;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
  .text-success {
    color: $color-validation;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  .add-new-map {
    @extend %abstract-popup;
    background-color: $bg-color-pure-white;
    display: $d-flx;
    flex-direction: column;
    justify-content: $ctr;
    height: 480px;
    width: 530px;

    &__close {
      cursor: $c-pointer;
      position: $pos-abs;
      right: 16px;
      top: 16px;
    }

    &__container {
      align-self: $ctr;
      height: 380px;
      max-height: 380px;
      max-width: 420px;
      width: 420px;
      padding-top: 15px;

      img {
        width: $width-full;
        height: $height-full;
        object-fit: contain;
        overflow: hidden;
      }
    }

    &__title {
      color: $color-dark-blue;
      font-size: 16px;
      font-weight: $font-weight-light;
      text-align: $ctr;
      position: $pos-abs;
      transform: translate(-50%);
      left: 50%;
      top: 12px;
    }

    .container-btn-rollback {
      text-align: end;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
}

.sector-warning {
  width: 210px;
  margin-left: auto;
  margin-right: auto;
}

.sector-bravo {
  width: 190px;
  margin-left: auto;
  margin-right: auto;
}
